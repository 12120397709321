/* Custom layout */
.custom-layout-cp-2 {
  height: 150px;
}
.custom-layout-cp-2 .react-colorful {
  top: -30px;
  width: 150px;
  height: 180px;
  padding: 16px;
  border-radius: 12px;
  margin-left: -16px;
  margin-right: -16px;
}

.custom-layout-cp-2 .react-colorful__saturation-pointer {
  width: 20px;
  height: 20px;
}

.custom-layout-cp-2 .react-colorful__saturation {
  border-radius: 10px;
  border-bottom: none;
}

.custom-layout-cp-2 .react-colorful__hue {
  order: -1;
  left: 160px;
  width: 150px;
  top: 60px;
}

.custom-layout-cp-2 .react-colorful__alpha {
  order: -1;
  left: 160px;
  top: 15px;
}

.custom-layout-cp-2 .react-colorful__hue,
.custom-layout-cp-2 .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
  width: 220px;
}

.custom-layout-cp-2 .react-colorful__hue-pointer,
.custom-layout-cp-2 .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}

.custom-layout-cp-2 .hexTxt {
  border-radius: 15px;
  background-color: #f8f8f8;
  padding: 20;
  position: 'absolute';
  width: '230px';
  top: '95px';
  left: '190px';
}
