body {
  font-family: sans-serif;
}

#app {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.leaflet-text-label-svg textarea {
  font-family: sans-serif;
  font-size: 16px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
  background: rgba(255, 255, 255, 0.75);
  outline: none;
  box-shadow: none;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  color: #000;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: -6px;
}

.leaflet-text-label-svg div {
  padding: 4px;
  border-radius: 3px;
}

.leaflet-text-label-svg text {
  paint-order: stroke;
  stroke: #000;
  fill: #fff;
  stroke-width: 2px;
}

.leaflet-text-label-popup .leaflet-popup-tip-container {
  display: none;
}

.leaflet-text-label-popup .leaflet-popup-content-wrapper {
  background: rgb(78, 195, 142);
  border-radius: 40px;
  width: 40px;
  height: 40px;
}

.leaflet-text-label-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  width: 40px !important;
  height: 40px;
  margin: 0;
}

.leaflet-text-label-popup .leaflet-popup-content {
  margin: 0px 5px;
  line-height: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaflet-text-label-popup .lealfet-text-label-popup-item-btn {
  width: 28px;
  height: 28px;
  background-image: url('./images/bin.svg');
  background-size: contain;
}

.leaflet-popup.custom-popup .leaflet-popup-content {
  margin: 5px 10px;
  width: 230px;
}

.leaflet-popup.custom-popup .leaflet-popup-content-wrapper {
  background-color: white;
  font-size: 11px;
}

.crosshair-cursor-enabled,
.crosshair-cursor-enabled * {
  cursor: crosshair;
}
