.select {  
    background-color: #f8f8f8;
      /* inline SVG */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23212121'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius:7px;
    border:none;
    color: #2c2a2a;
    padding: 10px;
    width: 200px;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.custom-layout-offset .react-colorful__saturation {
  display: none;
}

.custom-layout-offset .react-colorful__hue {
  display: none;
}

.custom-layout-offset .react-colorful {
  height: unset;
}

/* Custom layout */
.custom-layout-cp {
  height: 150px;
}
.custom-layout-cp .react-colorful {
  top: -30px;
  width: 150px;
  height: 180px;
  padding: 16px;
  border-radius: 12px;
}

.custom-layout-cp .react-colorful__saturation-pointer {
  width: 20px;
  height: 20px;
}

.custom-layout-cp .react-colorful__saturation {
  border-radius: 10px;
  border-bottom: none;
}

.custom-layout-cp .react-colorful__hue {
  order: -1;
  left: 160px;
  width: 150px;
  top: 30px;
}

.custom-layout-cp .react-colorful__alpha {
  order: -1;
  left: 160px;
  top: 45px;
}

.custom-layout-cp .react-colorful__hue,
.custom-layout-cp .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
  width: 220px;
}

.custom-layout-cp .react-colorful__hue-pointer,
.custom-layout-cp .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}

.custom-layout-cp .hexTxt {
  border-radius: 15px;
  background-color: #f8f8f8;
  padding: 20; 
  position: 'absolute'; 
  width: '230px'; 
  top: '95px'; 
  left: '190px';
}