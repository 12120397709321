@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap');
/* print styles */
@media print {
  @page {
    margin: 2cm;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    color: #000;
    background-color: #fff;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  header,
  footer,
  aside,
  nav,
  form,
  iframe,
  button {
    display: none !important;
  }
  .MuiSnackbar-root,
  .MuiStepper-root,
  .MuiFormLabel-root,
  .MuiInput-root,
  .MuiInputLabel-root,
  .MuiInput-formControl {
    display: none !important;
  }
  img,
  svg {
    display: block;
    max-width: 100%;
  }
  h1 {
    page-break-before: always;
    break-before: always;
  }
  table {
    page-break-inside: avoid;
    break-inside: avoid;
  }
  div[class^='ApplicationType-'] {
    display: none !important;
  }
  .map-flip-btn,
  .map-print-btn {
    display: none !important;
  }
}
#root {
  color: #4d4d4d;
  overflow: hidden;
}
#root button:focus {
  outline: 0;
}
#root button {
  cursor: pointer;
}
* {
  font-family: 'Outfit', 'Open Sans', 'sans-serif';
}
.MuiAutocomplete-popper {
  left: 1px !important;
  padding: 0 !important;
}
.MuiAutocomplete-paper {
  padding: 1rem 0 !important;
  border-radius: 20px !important;
  margin-top: 20px !important;
}
.MuiAutocomplete-listbox {
  padding: 0 1rem !important;
}
.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5000ms linear 5000ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 5000ms linear;
}
.fade-exit-done {
  opacity: 0;
}
.leaflet-popup-scrolled {
  border-bottom: none !important;
  border-top: none !important;
  margin: 10px;
}

/* Custom Date picker */
:root {
  --rdp-cell-size: 45px;
  --rdp-accent-color: #4dc490;
  --rdp-background-color: #4dc490;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #22b573;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0);
}

.MuiPickersDay-dayDisabled {
  color: #bdbdbd !important;
}

.cesium-widget canvas {
  width: 100dvw !important;
  height: 100dvh !important;
}

.rotate-marker-icon-2 {
  background: #4ec38e;
  opacity: 1 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: -1 !important;
  margin-top: -20px !important;
  margin-left: -20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none !important;
}

.circular-context-menu-popup .leaflet-popup-close-button {
  display: none;
}
.circular-context-menu-popup .leaflet-popup-tip-container {
  display: none;
}
.circular-context-menu-popup .leaflet-popup-content-wrapper {
  background: transparent;
  box-shadow: none !important;
}
.circular-context-menu-popup .leaflet-popup-content {
  width: 0 !important;
  margin: 0 !important;
}
.circular-context-menu-marker {
  display: none;
}
