@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap);
.react-tel-input {
  font-family: 'Zwo Pro', 'Roboto', 'Helvetica', Arial, sans-serif;
}

.react-tel-input .form-control + div:before {
  content: 'Mobile number*';
  font-size: 11px;
  width: 90px;
  left: 0;
  padding: 0;
}

.react-tel-input .form-control {
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.react-tel-input .selected-flag {
  padding: 0;
}

.react-tel-input .form-control:focus {
  border-color: #24b574;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}

.react-tel-input .form-control:focus + div:before {
  color: #24b574;
}

.react-tel-input .form-control.invalid-number {
  border-bottom: 1px solid #FF9900;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}

.react-tel-input .form-control.invalid-number:focus {
  box-shadow: 0 0 0 1px #FF9900
}

.react-tel-input .form-control.invalid-number + div:before {
  content: 'Mobile number*';
  font-size: 11px;
  width: 90px;
  left: 0;
  padding: 0;
  color: #FF9900;
}

.leaflet-contextmenu {
    display: none;
    box-shadow: 0 1px 7px rgba(0,0,0,0.4);
    border-radius: 4px;
    padding: 4px 0;
    background-color: #fff;
    cursor: default;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

.leaflet-contextmenu a.leaflet-contextmenu-item {
    display: block;
    color: #222;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    padding: 0 12px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    cursor: default;
    outline: none;
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled {
    opacity: 0.5;
}

.leaflet-contextmenu a.leaflet-contextmenu-item.over {
    background-color: #f4f4f4;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled.over {
    background-color: inherit;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.leaflet-contextmenu-icon {
    margin: 2px 8px 0 0;
    width: 16px;
    height: 16px;
    float: left;
    border: 0;
}

.leaflet-contextmenu-separator {
    border-bottom: 1px solid #ccc;
    margin: 5px 0;
}

.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
	transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
	/* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
	transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

body {
  font-family: sans-serif;
}

#app {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.leaflet-text-label-svg textarea {
  font-family: sans-serif;
  font-size: 16px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
  background: rgba(255, 255, 255, 0.75);
  outline: none;
  box-shadow: none;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  color: #000;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: -6px;
}

.leaflet-text-label-svg div {
  padding: 4px;
  border-radius: 3px;
}

.leaflet-text-label-svg text {
  paint-order: stroke;
  stroke: #000;
  fill: #fff;
  stroke-width: 2px;
}

.leaflet-text-label-popup .leaflet-popup-tip-container {
  display: none;
}

.leaflet-text-label-popup .leaflet-popup-content-wrapper {
  background: rgb(78, 195, 142);
  border-radius: 40px;
  width: 40px;
  height: 40px;
}

.leaflet-text-label-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  width: 40px !important;
  height: 40px;
  margin: 0;
}

.leaflet-text-label-popup .leaflet-popup-content {
  margin: 0px 5px;
  line-height: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaflet-text-label-popup .lealfet-text-label-popup-item-btn {
  width: 28px;
  height: 28px;
  background-image: url(/static/media/bin.69d7741c.svg);
  background-size: contain;
}

.leaflet-popup.custom-popup .leaflet-popup-content {
  margin: 5px 10px;
  width: 230px;
}

.leaflet-popup.custom-popup .leaflet-popup-content-wrapper {
  background-color: white;
  font-size: 11px;
}

.crosshair-cursor-enabled,
.crosshair-cursor-enabled * {
  cursor: crosshair;
}

::-webkit-scrollbar {
  width: 0;
}

.drawer-layout .simplebar-content-wrapper {
  min-height: 100%;
  overflow-y: scroll;
}

#text-area-input {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 4px;
  }
}

:root {
  --swiper-navigation-size: 8px;
}

.suggestion-Container {
  padding: 8px;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 375px;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999;
  padding: 8px;
  left: -20px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.select {  
    background-color: #f8f8f8;
      /* inline SVG */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23212121'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius:7px;
    border:none;
    color: #2c2a2a;
    padding: 10px;
    width: 200px;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
}
.select {  
    background-color: #f8f8f8;
      /* inline SVG */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23212121'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius:7px;
    border:none;
    color: #2c2a2a;
    padding: 10px;
    width: 200px;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
}
.select {  
    background-color: #f8f8f8;
      /* inline SVG */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23212121'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius:7px;
    border:none;
    color: #2c2a2a;
    padding: 10px;
    width: 200px;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
}
.select {  
    background-color: #f8f8f8;
      /* inline SVG */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23212121'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius:7px;
    border:none;
    color: #2c2a2a;
    padding: 10px;
    width: 200px;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
}

.custom-layout-offset .react-colorful__saturation {
  display: none;
}

.custom-layout-offset .react-colorful__hue {
  display: none;
}

.custom-layout-offset .react-colorful {
  height: unset;
}

/* Custom layout */
.custom-layout-cp {
  height: 150px;
}
.custom-layout-cp .react-colorful {
  top: -30px;
  width: 150px;
  height: 180px;
  padding: 16px;
  border-radius: 12px;
}

.custom-layout-cp .react-colorful__saturation-pointer {
  width: 20px;
  height: 20px;
}

.custom-layout-cp .react-colorful__saturation {
  border-radius: 10px;
  border-bottom: none;
}

.custom-layout-cp .react-colorful__hue {
  order: -1;
  left: 160px;
  width: 150px;
  top: 30px;
}

.custom-layout-cp .react-colorful__alpha {
  order: -1;
  left: 160px;
  top: 45px;
}

.custom-layout-cp .react-colorful__hue,
.custom-layout-cp .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
  width: 220px;
}

.custom-layout-cp .react-colorful__hue-pointer,
.custom-layout-cp .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}

.custom-layout-cp .hexTxt {
  border-radius: 15px;
  background-color: #f8f8f8;
  padding: 20; 
  position: 'absolute'; 
  width: '230px'; 
  top: '95px'; 
  left: '190px';
}
/* Custom layout */
.custom-layout-cp-2 {
  height: 150px;
}
.custom-layout-cp-2 .react-colorful {
  top: -30px;
  width: 150px;
  height: 180px;
  padding: 16px;
  border-radius: 12px;
  margin-left: -16px;
  margin-right: -16px;
}

.custom-layout-cp-2 .react-colorful__saturation-pointer {
  width: 20px;
  height: 20px;
}

.custom-layout-cp-2 .react-colorful__saturation {
  border-radius: 10px;
  border-bottom: none;
}

.custom-layout-cp-2 .react-colorful__hue {
  order: -1;
  left: 160px;
  width: 150px;
  top: 60px;
}

.custom-layout-cp-2 .react-colorful__alpha {
  order: -1;
  left: 160px;
  top: 15px;
}

.custom-layout-cp-2 .react-colorful__hue,
.custom-layout-cp-2 .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
  width: 220px;
}

.custom-layout-cp-2 .react-colorful__hue-pointer,
.custom-layout-cp-2 .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}

.custom-layout-cp-2 .hexTxt {
  border-radius: 15px;
  background-color: #f8f8f8;
  padding: 20;
  position: 'absolute';
  width: '230px';
  top: '95px';
  left: '190px';
}

.map-control-scalebar-scale-item {
  cursor: pointer;
  padding: 2px 5px;
}

.map-control-scalebar-scale-item:hover {
  background: lightgray;
}

.map-control-scalebar-text {
  cursor: pointer;
  height: 20px;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  width: 60%;
}

.map-control-scalebar-custom-scale {
  padding: 0.2em;
  width: 10em;
}

.map-control-scalebar-dropdown {
  border: 2px solid #777;
  border-bottom: none;
  background: white;
  max-height: 30em;
  overflow-y: hidden;
  transition: max-height 0.2s ease-in-out;
}

/* print styles */
@media print {
  @page {
    margin: 2cm;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    color: #000;
    background-color: #fff;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  header,
  footer,
  aside,
  nav,
  form,
  iframe,
  button {
    display: none !important;
  }
  .MuiSnackbar-root,
  .MuiStepper-root,
  .MuiFormLabel-root,
  .MuiInput-root,
  .MuiInputLabel-root,
  .MuiInput-formControl {
    display: none !important;
  }
  img,
  svg {
    display: block;
    max-width: 100%;
  }
  h1 {
    page-break-before: always;
    page-break-before: always;
    break-before: always;
  }
  table {
    page-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
  div[class^='ApplicationType-'] {
    display: none !important;
  }
  .map-flip-btn,
  .map-print-btn {
    display: none !important;
  }
}
#root {
  color: #4d4d4d;
  overflow: hidden;
}
#root button:focus {
  outline: 0;
}
#root button {
  cursor: pointer;
}
* {
  font-family: 'Outfit', 'Open Sans', 'sans-serif';
}
.MuiAutocomplete-popper {
  left: 1px !important;
  padding: 0 !important;
}
.MuiAutocomplete-paper {
  padding: 1rem 0 !important;
  border-radius: 20px !important;
  margin-top: 20px !important;
}
.MuiAutocomplete-listbox {
  padding: 0 1rem !important;
}
.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5000ms linear 5000ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 5000ms linear;
}
.fade-exit-done {
  opacity: 0;
}
.leaflet-popup-scrolled {
  border-bottom: none !important;
  border-top: none !important;
  margin: 10px;
}

/* Custom Date picker */
:root {
  --rdp-cell-size: 45px;
  --rdp-accent-color: #4dc490;
  --rdp-background-color: #4dc490;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #22b573;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0);
}

.MuiPickersDay-dayDisabled {
  color: #bdbdbd !important;
}

.cesium-widget canvas {
  width: 100dvw !important;
  height: 100dvh !important;
}

.rotate-marker-icon-2 {
  background: #4ec38e;
  opacity: 1 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  z-index: -1 !important;
  margin-top: -20px !important;
  margin-left: -20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none !important;
}

.circular-context-menu-popup .leaflet-popup-close-button {
  display: none;
}
.circular-context-menu-popup .leaflet-popup-tip-container {
  display: none;
}
.circular-context-menu-popup .leaflet-popup-content-wrapper {
  background: transparent;
  box-shadow: none !important;
}
.circular-context-menu-popup .leaflet-popup-content {
  width: 0 !important;
  margin: 0 !important;
}
.circular-context-menu-marker {
  display: none;
}

