.react-tel-input {
  font-family: 'Zwo Pro', 'Roboto', 'Helvetica', Arial, sans-serif;
}

.react-tel-input .form-control + div:before {
  content: 'Mobile number*';
  font-size: 11px;
  width: 90px;
  left: 0;
  padding: 0;
}

.react-tel-input .form-control {
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.react-tel-input .selected-flag {
  padding: 0;
}

.react-tel-input .form-control:focus {
  border-color: #24b574;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}

.react-tel-input .form-control:focus + div:before {
  color: #24b574;
}

.react-tel-input .form-control.invalid-number {
  border-bottom: 1px solid #FF9900;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}

.react-tel-input .form-control.invalid-number:focus {
  box-shadow: 0 0 0 1px #FF9900
}

.react-tel-input .form-control.invalid-number + div:before {
  content: 'Mobile number*';
  font-size: 11px;
  width: 90px;
  left: 0;
  padding: 0;
  color: #FF9900;
}
